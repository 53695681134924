import { Team } from 'model/Team';
import { User } from 'model/User';
import Service from './index';

export interface SearchTeamParams {
  query: string;
}

async function getAllTeams(params: any) {
  try {
    const response = await Service.apiRequest.get<Team[]>(`teams`, { params });

    return response.data;
  } catch (e) {
    return [];
  }
}

async function getTeamById(params: { teamId: string }) {
  try {
    const response = await Service.apiRequest.get(`teams/${params.teamId}`);

    return response.data;
  } catch (e) {
    return null;
  }
}

async function editTeamProfile(params: { teamId: string }, data: any) {
  try {
    const response = await Service.apiRequest.put<Team>(`teams/${params.teamId}`, { ...data });
    return response.data;
  } catch (e) {
    return null;
  }
}

async function addUserToTeamByUserId(params: {
  teamId: string;
  userId: string;
  ticketCode: string;
}) {
  let apiEndpoint = `teams/${params.teamId}/users/?userId=${params.userId}`;

  if (params.ticketCode) {
    apiEndpoint += `&ticketCode=${params.ticketCode}`;
  }

  try {
    const response = await Service.apiRequest.post<User>(apiEndpoint);
    return response.data;
  } catch (e) {
    return null;
  }
}

async function removeUserFromTeam(params: { teamId: string; userId: string }) {
  try {
    const response = await Service.apiRequest.delete<Team>(
      `teams/${params.teamId}/users/${params.userId}`,
    );

    return response.data;
  } catch (e) {
    return null;
  }
}

async function searchTeams(params: SearchTeamParams) {
  try {
    const response = await Service.apiRequest.get<Team[]>(`teams/search/`, { params });

    return response.data;
  } catch (e) {
    return [];
  }
}

function sortTeamWithCaptainOnTop(team) {
  const result: User[] = [];
  let captain;
  const users = team.users || [];
  users.forEach((user) => {
    if (user.userId !== team.captainUserId) {
      result.push(user);
    } else {
      captain = user;
    }
  });
  if (captain) {
    result.unshift(captain);
  }
  return result;
}

export default {
  getTeamById,
  editTeamProfile,
  getAllTeams,
  addUserToTeamByUserId,
  removeUserFromTeam,
  searchTeams,
  sortTeamWithCaptainOnTop,
};
