import classnames from 'classnames';
import { Team } from 'model/Team';
import { useCallback, useMemo, useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import TeamService from 'services/TeamService';
import { TeamDetailsAddUserForm } from './TeamDeatilsAddUserForm';
import { TeamDetailsForm } from './TeamDetailsForm';
import { TeamDetailsUsers } from './TeamDetailsUsers';
import { TeamLeaderboard } from './TeamLeaderboard';

export interface TeamTabsProps {
  team: Team;
  teamId: string;
  setTeam: (team: Team) => void;
}

const tabsList = [
  { tabId: '1', tabName: 'Details' },
  { tabId: '2', tabName: 'Users' },
  { tabId: '3', tabName: 'Leaderboard' },
  { tabId: '4', tabName: 'Add' },
];

function TabNav({ toggle, activeTab }) {
  return (
    <Nav tabs>
      {tabsList.map((tab, idx) => (
        <NavItem key={idx}>
          <NavLink
            className={classnames({ active: activeTab === tab.tabId })}
            onClick={() => {
              toggle(tab.tabId);
            }}
            style={{ cursor: 'pointer' }}
          >
            {tab.tabName}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

export function TeamTabs({ team, teamId, setTeam }: TeamTabsProps) {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab],
  );

  const sortedTeam = useMemo(() => TeamService.sortTeamWithCaptainOnTop(team), [team]);

  return (
    <div>
      <TabNav toggle={toggle} activeTab={activeTab} />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <TeamDetailsForm team={team} teamId={teamId} setTeam={setTeam} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <TeamDetailsUsers
                teamUsers={sortedTeam}
                teamId={teamId}
                teamCaptainId={team?.captainUserId}
                teamTitle={team.title}
                setTeam={setTeam}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <TeamLeaderboard team={team} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
              <TeamDetailsAddUserForm teamId={teamId} setTeam={setTeam} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}
